<template>
  <div>
    <b-card header="Edit Organizer">
      <spinner-loader :loading="loading" />

      <event-organizer-form
        v-if="!loading"
        :submit="editOrganizer"
        :organizer.sync="organizer"
      />

      <upload-progress
        :progress="progress"
        class="mt-2"
      />
    </b-card>
  </div>
</template>

<script>
import handleAlerts from '@/common/compositions/common/handleAlerts'
import EventOrganizerForm from '@/common/components/EventOrganizers/EventOrganizerForm.vue'
import SpinnerLoader from '@/common/components/common/Table/SpinnerLoader.vue'
import UploadProgress from '@/common/components/common/FormInputs/UploadProgress.vue'
import uploadPercentage from '@/common/compositions/common/uploadPercentage'

export default {
  name: 'EditEventOrganizer',
  components: {
    EventOrganizerForm,
    SpinnerLoader,
    UploadProgress,
  },
  data() {
    return {
      organizer: {},
      loading: true,
    }
  },
  computed: {
    formattedOrganizer() {
      return {
        name: this.organizer.name,
        first_name: this.organizer.first_name,
        last_name: this.organizer.last_name,
        phone_number: this.organizer.phone_number,
        email: this.organizer.email,
        about: this.organizer.about,
        facebook_link: this.organizer.facebook_link,
        youtube_link: this.organizer.youtube_link,
        logo: this.organizer.logo[0],
        ...this.organizer.location,
      }
    },
  },
  setup() {
    const { successfulOperationAlert } = handleAlerts()
    const { progress, calculateUploadPercentage } = uploadPercentage()
    return {
      successfulOperationAlert, progress, calculateUploadPercentage,
    }
  },
  created() {
    this.getOrganizer()
  },
  methods: {
    editOrganizer() {
      return this.$entities.post(`internalops/organizer/${this.$route.params.id}?_method=PUT`, this.formattedOrganizer, {
        onUploadProgress: progressEvent => { this.calculateUploadPercentage(progressEvent) },
      }).then(() => {
        this.successfulOperationAlert('Organizer is updated successfully')
        this.$router.back()
      })
    },
    getOrganizer() {
      this.$entities.get(`/internalops/organizer/${this.$route.params.id}`).then(res => {
        this.organizer = {
          ...res.data.data,
          logo: [res.data.data.logo],
          location: res.data.data.address[0] && res.data.data.location
            ? {
              ...(res.data.data.address[0]),
              lat: res.data.data.location.coordinates[1],
              lng: res.data.data.location.coordinates[0],
            } : {},
        }
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
